import React, { Component, Suspense, Fragment, useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as cookie from "../../../cookie";
import * as helper from "../../../helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import EmailShareButton from "../../share-button/email";
import WhatsappShareButton from "../../share-button/whatsapp";
import FacebookShareButton from "../../share-button/facebook";
import NameCardShareButton from "../../share-button/namecard";
import Namecard from "../Namecard/index";

import * as AccountAction from "../../../redux/actions/accountAction";

import styles from "./share-section.module.css";

function ReferralRecord(props) {
  const [jwtPayload, setJWTPayload] = useState(null);
  const logout = async() => {
    try{
      let loginResponse = await axios({
        url: `${process.env.REACT_APP_Api_Url}/account/logout`,
        method: "post",
        data: {
          jwt: props.state.account.jwt
        }
      });
    }
    catch(err){

    }
    props.dispatch(
      AccountAction.updateValue({
        name: "jwt",
        value: null
      })
    );
    cookie.setCookie("jwt_token", null, 0);
  };

  useEffect(() => {
    setJWTPayload(props.state.account.jwt && helper.parseJwt(props.state.account.jwt));
  }, [props.state.account.jwt]);

  return (
    <div className={styles["main-wrapper"]}>
      <div className={styles["inner-wrapper"]}>
        <div className={styles["welcome-message"]}>
          <h2>{props.staticContent && `${props.staticContent.welcomeMessage[props.state.languageCode]}`}</h2>
          <style>
                  {`
                    .${styles["btn-signout"]}  {
                        color: var(--${props.staticContent && props.staticContent.contentTextColor.iv[0].data.name.iv});
                    }

                  `}
            </style>
          <FontAwesomeIcon icon={faSignOutAlt} onClick={() => logout()} className={`button ${styles["btn-signout"]}`} />
        </div>
        <div className={styles["share-referral-code-wrapper"]}>
          <div className={styles["left-wrapper"]}>
            <h4>
              <div>{props.staticContent && props.staticContent.yourReferralCode[props.state.languageCode]}</div>
              <span style={{color: `var(--${props.staticContent && props.staticContent.referralCodeTextColor.iv[0].data.name.iv})`}}>{jwtPayload && jwtPayload.MgmCode}</span>
            </h4>
            <div className={styles["buttons-wrapper"]}>
              <CopyToClipboard text={`${process.env.REACT_APP_ReferralShortURL}${jwtPayload && jwtPayload.MgmCode}`}>
                <div className={`button ${styles["btn-copy-wrapper"]}`} style={{color: `var(--${props.staticContent && props.staticContent.btnCopyTextColor.iv[0].data.name.iv})`, backgroundColor: `var(--${props.staticContent && props.staticContent.btnCopyBackgroundColor.iv[0].data.name.iv})`}}>
                  <div className={styles["icon-copy-wrapper"]}>
                    <img src={props.staticContent && props.staticContent.btnCopyIcon.iv[0].url} />
                  </div>
                  <span>{props.staticContent && props.staticContent.btnCopyText[props.state.languageCode]}</span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div className={styles["right-wrapper"]}>
            <div className={styles["text-share"]}>{props.staticContent && props.staticContent.shareText[props.state.languageCode]}</div>
            <FacebookShareButton icon={props.staticContent && props.staticContent.btnShareFacebookIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor = {props.staticContent && props.staticContent.btnShareFacebookBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            <WhatsappShareButton icon={props.staticContent && props.staticContent.btnShareWhatsappIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode}  backgroundColor = {props.staticContent && props.staticContent.btnShareWhatsappBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
            {/* <EmailShareButton icon={props.staticContent && props.staticContent.btnShareEmailIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor = {props.staticContent && props.staticContent.btnShareEmailBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} /> */}
            <NameCardShareButton icon={props.staticContent && props.staticContent.btnShareNamecardIcon.iv[0].url} mgmCode={jwtPayload && jwtPayload.MgmCode} backgroundColor = {props.staticContent && props.staticContent.btnShareNamecardBackgroundColor.iv[0].data.colorCode.iv} showDefault={true} />
          </div>
        </div>
        {props.state.account.showNamecard && <Namecard referralCode={jwtPayload && jwtPayload.MgmCode} serviceType={props.state.account.serviceType} />}
      </div>
    </div>
  );
}

export default withRouter(
  connect(state => {
    return {
      state: {
        languageCode: state.i18n.languageCode,
        jsonMapping: state.cms.jsonMapping,
        account: state.account
      }
    };
  })(ReferralRecord)
);
